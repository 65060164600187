.biz {
  color: rgb(252, 215, 94);
}
.title {
  margin: 50px;
  text-align: center;
  font-weight: 800;
  color: rgb(252, 215, 94);
  text-shadow: -3px -3px 4px  rgba(254, 77, 77, 0.93);
  font-size: 5em;
}
.subtitle {
  margin-top: 20px;
  text-align: center;
  font-weight: 800;
  color: rgb(252, 215, 94);
  text-shadow: -3px -3px 4px rgba(254, 77, 77, 0.93);
  font-size: 2.5em;
}
.subtitleh2 {
  margin: 50px;
  text-align: left;
  font-weight: 800;
  color: rgb(252, 215, 94);
  text-shadow: -3px -3px 4px  rgba(254, 77, 77, 0.93);
  font-size: 3em;
}
.input_category {
  height: 59px;
}

.label_input {
  color: rgb(252, 215, 94);
}
.img_title {
  margin-left: 90px;
  margin-top: 15px;
  border: 3px solid black;
  width: 150px;
  height: 150px;
  box-shadow: 2px 2px 5px rgba(255, 255, 255, 0.5) inset,
    -2px -2px 5px rgba(0, 0, 0, 0.5) inset;
}
.containercreat {
  display: flex;
  justify-content: center;
  align-items: center;
}
.imagecreat {
  border: 5px solid rgb(252, 215, 94);
  height: 215px;
  width: 300px;
  margin-bottom: 2%;
}
.buttonCreatCom {
  width: 4em;
  height: 4em;
  border-radius: 50%;
  margin-left: 90%;
  margin-bottom: 10%;
}
.buttenCompletionOfAnOrder {
  background-color: rgba(252, 215, 94, 0);
  width: 90%;
  color: aliceblue;
  margin: 2em 0;
}
.inputBookTable {
  align-items: center;
  width: 160px;
  height: 80px;
}
.table-page .row {
  width: 227px;
  display: flex;
  flex-wrap: nowrap;
}
.table-page .row > * {
  align-items: center;
  width: 70%;
  padding: 0 0 0 0;
}
.pAbuot{
  color: rgb(252, 215, 94);
  border-bottom: 2px solid  rgb(252, 215, 94);
  font-weight: 500;
  font-size: 1.5em;
}
