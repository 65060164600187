.crmHeader {
  background-color: rgba(252, 215, 94, 1);
  font-size: 1.2em;
}
.table > :not(caption) > * > * {
  opacity: 0.7;
}

.tablacrm {
  font-weight: bold;
  color: black;
}

.paymentForm {
  border: 4px solid black;
  border-radius: 50px;
  margin: 2%;
  display: block;
  width: 330px;
  height: 50px;
  margin: 10px 0 10px 0px;
}
.buttonPay {
  margin: 10px 0 10px 0px;
  width: 330px;
}
.paymentCard {
  display: block;
  color: black;
  margin-top: 50px;
  margin-bottom: 50px;
}