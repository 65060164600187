* {
  font-family: "Roboto", "san-serif";
}

body {
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("../../public/Image/IMG_4084bady.jpg");
  background-size:cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
} 

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.red-backgroud {
  background-color: red;
  animation: test 2s infinite;
}

@keyframes test {
  0%,
  100% {
    background-color: red;
  }
  50% {
    background-color: black;
  }
}
